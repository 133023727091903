import axios from "axios";

export const getDragonBoatFestival = (): Promise<IDragonBoatFestival> => {
  return axios.get(`/activity/dragon-boat-festival`);
};

export const joinDragonBoatFestival = (
  type: 0 | 1
): Promise<IDragonBoatFestival> => {
  return axios.post(`/activity/dragon-boat-festival/${type}`);
};

export const getAnswerToDay = (): Promise<{
  box_id: number;
  data: MallActivityVerifyAnswerItem[];
}> => {
  return axios.get("/activity/answer/to-day");
};

export const verifyAnswer = () => {
  return axios.post("/activity/answer/verify");
};

export interface MallActivityVerifyAnswerItem {
  title: string;
  words: string[];
  value: string;
  answer: string;
}

export interface IDragonBoatFestival {
  sweet_num: number;
  salty_num: number;
  current_camp: number;
  current_box_id: number;
}

export type IDragonType = "salty" | "sweet" | "";
