








































import { Component, Vue, Watch } from "vue-property-decorator";
import AnswerCard from "@/components/AnswerCard.vue";
import {
  getAnswerToDay,
  MallActivityVerifyAnswerItem,
  verifyAnswer,
} from "@/api/activity.api";
import { Toast } from "vant";

@Component({
  components: { AnswerCard },
})
export default class SteamKnowledgeContest extends Vue {
  box_id = 0;
  sourceData: MallActivityVerifyAnswerItem[] = [];
  result: boolean | null = null;
  showError = false;

  async fetchData() {
    const toast = Toast.loading("加载中...");
    const data = await getAnswerToDay().finally(() => toast.clear());
    this.box_id = data.box_id;
    this.sourceData = data.data;
  }

  async handlePopup() {
    if (this.result == false) {
      this.result = null;
      this.showError = true;
      return;
    }
    return this.$router.push({
      name: "BlindBoxDetail",
      params: { id: String(this.box_id) },
    });
  }

  handleTryAgain() {
    this.result = null;
    this.showError = false;
    this.fetchData();
  }

  handleComplete() {
    const err: string[] = [];
    this.sourceData.forEach((item) => {
      if (!item.value) {
        Toast("5道题全部回答后才能提交哦~");
        throw new Error("5道题全部回答后才能提交哦~");
      }
      if (item.value !== item.answer) err.push(item.title);
    });
    if (err.length > 0) return (this.result = false);
    this.result = true;
  }

  created() {
    this.fetchData();
  }
}
